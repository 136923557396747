<template lang="pug">
.vc-sketch-bottom
  .vc-sketch-field.om-hex-alpha-row.mt-2
    .vc-sketch-field--single
      ed-in(label="hex" :value="hex" @change="inputChange")
    .vc-sketch-field--single.d-flex(v-if="!disableAlpha")
      span.vc-input__label {{ $t('opacity') }}
      .value-indicator.alpha-value-indicator
        input#alpha.value-style(
          type="number"
          v-model="colorAlpha"
          min="0"
          max="100"
          @input="handleInput"
        )
        .unit %
  .vc-sketch-controls.mt-3
    .vc-sketch-sliders
      .vc-sketch-hue-wrap
        hue(v-model="colors" @change="childChange")
      .vc-sketch-alpha-wrap.mt-3(v-if="!disableAlpha")
        alpha(v-model="colors" @change="childChange")
</template>

<script>
  import hue from '@/editor/components/color/components/common/Hue.vue';
  import alpha from '@/editor/components/color/components/common/Alpha.vue';
  import editableInput from '@/editor/components/color/components/common/EditableInputOm.vue';

  const MIN_ALPHA = 0;
  const MAX_ALPHA = 100;

  export default {
    name: 'SolidTab',
    components: {
      hue,
      alpha,
      'ed-in': editableInput,
    },
    props: ['disableAlpha', 'colors'],
    data() {
      return {
        currentAlpha: Math.round(this.colors.a * MAX_ALPHA),
      };
    },
    computed: {
      hex() {
        return this.colors.hex;
      },
      colorAlpha: {
        get() {
          return this.currentAlpha;
        },
        set(alpha) {
          let value = Number(alpha);
          if (isNaN(value)) value = MIN_ALPHA;

          value = Math.min(MAX_ALPHA, Math.max(MIN_ALPHA, value));
          this.currentAlpha = value;

          this.$emit('childChange', {
            h: this.colors.hsl.h,
            s: this.colors.hsl.s,
            l: this.colors.hsl.l,
            hsl: this.colors.hsl,
            hsv: this.colors.hsv,
            rgba: this.colors.rgba,
            a: value / MAX_ALPHA,
            source: 'rgba',
            hex: this.colors.hex,
          });
        },
      },
    },
    watch: {
      hex(v) {
        this.$emit('change', { hex: v });
      },
      colors: {
        deep: true,
        handler(newColors) {
          this.currentAlpha = Math.round(newColors.a * MAX_ALPHA);
        },
      },
    },
    methods: {
      handleInput(event) {
        const value = Number(event.target.value);
        if (value > MAX_ALPHA) {
          event.target.value = MAX_ALPHA.toString();
          this.colorAlpha = MAX_ALPHA;
        }
      },
      inputChange(data) {
        this.$emit('inputChange', data);
      },
      childChange(data) {
        if (data.source === 'rgba') {
          this.currentAlpha = Math.round((data.a || 0) * MAX_ALPHA);
        }

        const updatedData = {
          ...this.colors,
          ...data,
          a: data.source === 'rgba' ? data.a : this.currentAlpha / MAX_ALPHA,
        };

        this.$emit('childChange', updatedData);
      },
    },
  };
</script>
<style lang="sass" scoped>
  .vc-input__label
    align-self: center !important
    font-size: 12px !important
    line-height: 16px !important
    padding: 0 !important
    color: #505763 !important
    margin-right: 7px !important
    text-align: left !important
    display: block !important
  .value-indicator
    padding: 0 2rem 0 1rem
    .value-style
      align-self: center
    .unit
      align-self: center
      font-size: 14px
  #alpha
    width: 100%
  .alpha-value-indicator
    position: relative
    padding: 0
    .unit
      position: absolute
      right: 1rem
</style>
